<template>
    <v-container fluid class="pa-0" id="container">
        <v-carousel hide-delimiter-background hide-delimiters show-arrows-on-hover cycle height="100%">
            <v-carousel-item v-for="(_, i) in 4" :key="i">
                <v-sheet color="primary" height="100%" dark>
                    <v-img color="primary" :src="'./assets/img/slider/slide-' + (i + 1) + '.jpg'" class="slide" dark>
                        <v-row no-gutters class="fill-height" align="center" justify="center">
                            <v-col cols="auto text-center">
                                <div :class="{ 'text-h1': $vuetify.breakpoint.mdAndUp, 'text-h2': $vuetify.breakpoint.smAndDown }">
                                    <span class="respective-font">{{ $t("pages.home.slides." + (i + 1) + ".title") }}</span>
                                </div>
                                <div :class="{ 'text-h4': $vuetify.breakpoint.mdAndUp, 'text-h5': $vuetify.breakpoint.smAndDown }">
                                    <span class="antiga-font secondary--text text--lighten-3">{{ $t("pages.home.slides." + (i + 1) + ".subtitle") }}</span>
                                </div>
                            </v-col>
                        </v-row>
                    </v-img>
                </v-sheet>
            </v-carousel-item>
        </v-carousel>
        <ImageCarouselOverlay
            :value="promos.overlay"
            :images="promos.images"
            @close="promos.overlay = false"
            path="./uploads/"
            cycle
        ></ImageCarouselOverlay>
    </v-container>
</template>

<script>
    import ImageCarouselOverlay from "@/components/elements/ImageCarouselOverlay";

    export default {
        name: "home-view",
        components: { ImageCarouselOverlay },
        data: () => ({
            promos: {
                overlay: false,
                images: []
            }
        }),
        mounted() {
            // show promos
            this._request("promotions/get-all", { home: true }).then(response => {
                if(!response.images || response.images.length == 0) {
                    this.promos.images = [];
                    return;
                }
                this.promos.images = response.images;
                this.promos.overlay = true;
            });
        }
    };
</script>

<style scoped>
    #container {
        position: relative;
        height: 100%;
    }
    .slide {
        position: absolute;
        height: 100%;
    }
</style>