<template>
    <ContentLayout>
        <v-row>
            <v-col cols="12" md="6" lg="7" xl="8">
                <v-card elevation="8" :class="{ 'mx-4 my-4': $vuetify.breakpoint.mdAndUp }">
                    <v-sheet color="primary" height="440px" dark id="map-wrapper">
                        <div id="map">
                            <iframe
                                width="100%" height="100%" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"
                                :src="'https://maps.google.com/maps?width=100%25&amp;height=100%25&amp;hl=' + $i18n.locale + '&amp;q=12480%20Rue%20April,%20Pointe-aux-Trembles,%20QC%20H1B%205N5+(Lanternes%20Rouges)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed'"
                            ></iframe>
                        </div>
                        <v-row no-gutters class="fill-height" align="center" justify="center" id="map-loading">
                            <v-col cols="auto" class="text-h2">
                                <!--<span class="antiga-font">Map</span>-->
                                <v-progress-circular indeterminate size="80"></v-progress-circular>
                            </v-col>
                        </v-row>
                    </v-sheet>
                </v-card>
            </v-col>
            <v-col cols="12" md="6" lg="5" xl="4">
                <v-card elevation="8" :class="{ 'mx-4 my-4': $vuetify.breakpoint.mdAndUp }">
                    <v-card-title class="antiga-font primary--text"><h2>{{ $t("pages.contact.contact-us") }}</h2></v-card-title>
                    <v-divider class="mx-4"></v-divider>
                    <v-card-text class="text-body-1">
                        <v-list two-line>
                            <v-list-item :href="_env.VUE_APP_ADDRESS_LINK" target="_blank">
                                <v-list-item-icon>
                                    <v-icon color="primary">
                                        mdi-map-marker
                                    </v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>{{ _env.VUE_APP_ADDRESS }}</v-list-item-title>
                                    <v-list-item-subtitle>{{ $t("pages.contact.address") }}</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item :href="'tel:' + _env.VUE_APP_PHONE" target="_blank">
                                <v-list-item-icon>
                                    <v-icon color="primary">
                                        mdi-phone
                                    </v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>{{ _env.VUE_APP_PHONE }}</v-list-item-title>
                                    <v-list-item-subtitle>{{ $t("pages.contact.phone") }}</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item :href="'mailto:' + _env.VUE_APP_EMAIL" target="_blank">
                                <v-list-item-icon>
                                    <v-icon color="primary">
                                        mdi-email
                                    </v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>{{ _env.VUE_APP_EMAIL }}</v-list-item-title>
                                    <v-list-item-subtitle>{{ $t("pages.contact.email") }}</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12">
                <v-form ref="form" @submit.prevent @submit="submit">
                    <v-card elevation="8" :class="{ 'mx-4 my-4': $vuetify.breakpoint.mdAndUp }">
                        <v-card-title class="antiga-font primary--text"><h2>{{ $t("pages.contact.message") }}</h2></v-card-title>
                        <v-divider class="mx-4"></v-divider>
                        <v-card-text class="text-body-1">
                            <v-row>
                                <v-col cols="6" lg="3">
                                    <v-text-field
                                        :label="$t('pages.contact.form.first_name')"
                                        v-model="form.first_name"
                                        outlined
                                        :rules="[ $data.customRules.required ]" maxlength="30" :disabled="submitting"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="6" lg="3">
                                    <v-text-field
                                        :label="$t('pages.contact.form.last_name')"
                                        v-model="form.last_name"
                                        outlined
                                        :rules="[ $data.customRules.required ]" maxlength="30" :disabled="submitting"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="6" lg="3">
                                    <v-text-field
                                        :label="$t('pages.contact.email')"
                                        v-model="form.email"
                                        outlined
                                        :rules="[ $data.customRules.required, $data.customRules.email ]" :disabled="submitting"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="6" lg="3">
                                    <v-text-field
                                        :label="$t('pages.hiring.form.phone')"
                                        v-model="form.phone"
                                        v-mask="'(###) ###-####'"
                                        outlined
                                        :rules="[ $data.customRules.required, $data.customRules.phone ]" :disabled="submitting"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <v-textarea
                                        :label="$t('pages.contact.form.message')"
                                        v-model="form.message"
                                        outlined counter="500"
                                        :rules="[ $data.customRules.required ]" maxlength="500" :disabled="submitting"
                                    ></v-textarea>
                                </v-col>
                            </v-row>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="primary" large text type="submit" :loading="submitting">
                                <v-icon left>mdi-send</v-icon>{{ $t('pages.contact.submit.btn') }}
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-form>
            </v-col>
        </v-row>
    </ContentLayout>
</template>

<script>
    import ContentLayout from "@/components/layouts/Content";

    export default {
        name: "contact-view",
        components: { ContentLayout },
        data: () => ({
            submitting: false,
            form: {
                first_name: null,
                last_name: null,
                phone: null,
                email: null,
                message: null
            }
        }),
        methods: {
            submit() {
                if(this.$refs && this.$refs.form && this.$refs.form.validate()) {
                    this.submitting = true;
                    this._request("forms/contact", this.form).then(response => {
                        if(response.error) {
                            this.$root.snack.show({
                                //text: response.error,
                                text: this.$i18n.t("pages.contact.submit.error"),
                                icon: "mdi-alert-circle",
                                color: "error"
                            });
                            return;
                        }
                        
                        this.form.message = null;
                        this.$refs.form.resetValidation();
                        
                        this.$root.snack.show({
                            text: this.$i18n.t("pages.contact.submit.success"),
                            icon: "mdi-check",
                            color: "success"
                        });

                    }).finally(() => {
                        this.submitting = false;
                    });
                } else {
                    this.$root.snack.show({
                        text: this.$i18n.t("form-validation-error"),
                        icon: "mdi-alert-circle",
                        color: "error"
                    });
                }
            }
        }
    };
</script>

<style scoped>
    #map-wrapper {
        position: relative;
    }
    #map {
        z-index: 2;
        position: absolute;
        top: 0px; bottom: 0px; left: 0px; right: 0px;
    }
    #map-loading {
        z-index: 1;
    }
</style>