<template>
    <v-btn
        :text="!footer" :rounded="!footer" :outlined="footer" large class="mr-4 antiga-font" :color="footer ? 'white' : 'secondary'"
        @click="update"
    >
        <v-icon left>mdi-web</v-icon><h2>{{ $i18n.locale == "fr" ? "en" : "fr" }}</h2>
    </v-btn>
</template>

<script>
    export default {
        name: "language-element",
        props: {
            footer: {
                type: Boolean,
                default: false
            }
        },
        methods: {
            update() {
                this.$i18n.locale = this.$i18n.locale == 'fr' ? 'en' : 'fr';
                this.$vuetify.lang.current = this.$i18n.locale;
            }
        }
    };
</script>