<template>
    <v-dialog v-model="value" fullscreen hide-overlay persistent dark transition="fade-transition">
        <v-container fill-height fluid class="pa-0 ma-0 black" id="container">
            <div id="bg"></div>
            <v-row no-gutters id="content">
                <v-spacer v-if="$vuetify.breakpoint.smAndUp"></v-spacer>
                <v-col cols="12" sm="7" md="8" lg="9">
                    <v-container fill-height fluid class="antiga-font">
                        <v-row align="center" justify="center">
                            <v-col cols="12" align="center">
                                <v-img src="@/assets/img/logo-splash.svg" id="logo"></v-img>
                            </v-col>
                            <v-col cols="12" class="my-8">
                                <v-row align="center" justify="center">
                                    <v-col cols="auto">
                                        <v-btn outlined large rounded elevation="8" dark @click="enter('fr')"><h2>Français</h2></v-btn>
                                    </v-col>
                                    <v-col cols="auto">
                                        <v-img src="@/assets/img/lantern.png" class="mx-4" width="30"></v-img>
                                    </v-col>
                                    <v-col cols="auto">
                                        <v-btn outlined large rounded elevation="8" dark @click="enter('en')"><h2>English</h2></v-btn>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12" align="center" class="white--text body-2">
                                <h3 class="antiga-font">
                                    <p>Ce site est destiné à un public adulte. En accédant à ce site vous confirmez que vous êtes âgé de 18 ans et plus.</p>
                                    <p>This site is intended for an adult audience. By accessing this site you confirm that you are 18 years of age or older.</p>
                                </h3>
                            </v-col>
                            <v-col cols="12" align="center" class="white--text text-caption">
                                <div>&copy; {{ new Date().getFullYear() }} {{ _env.VUE_APP_TITLE }} — {{ $t("copyright") }}</div>
                                <div>{{ $t("developed_by") }} <a class="text-decoration-none white--text" :href="_env.VUE_APP_AUTHOR_WEBSITE" target="_blank">{{ _env.VUE_APP_AUTHOR_NAME }}</a></div>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-col>
            </v-row>
        </v-container>
    </v-dialog>
</template>

<script>
    export default {
        name: "splash-dialog",
        components: {},
        data: () => ({
            value: true
        }),
        methods: {
            enter(locale) {
                //console.log(locale);
                this.value = false;
                this.$i18n.locale = locale;
                this.$vuetify.lang.current = locale;
                this.$emit("enter");
            }
        }
    };
</script>

<style scoped>
    #logo {
        max-width: 450px;
    }

    #container {
        position: relative;
    }

    #bg {
        position: absolute;
        z-index: 1;
        top: 0px; bottom: 0px; left: 0px; right: 0px;
        background-image: url("@/assets/img/splash-bg-02.jpg");
        background-size: auto 100%;
        background-position-x: -400px;
    }

    #content {
        z-index: 2;
    }

    @media screen and (min-width: 960px) and (max-width: 1264px) { /* md */
        #bg {
            background-position-x: -500px;
        }
    }
    @media screen and (min-width: 600px) and (max-width: 960px) { /* sm */
        #bg {
            background-position-x: -600px;
        }
    }
    @media screen and (max-width: 600px) { /* xs */
        #logo {
            max-width: 350px;
        }
        #bg {
            opacity: 0.3;
            background-position-x: -700px;
        }
    }
</style>