import Vue from "vue";

import { i18n } from "./i18n";

Vue.mixin({
    data: () => ({
        customRules: {
            required: value => !!value || 
                i18n.t("rules.required"),
            number: value => {
                value = typeof value !== undefined && value != null ? (isNaN(value) ? value : value.toString()) : "";
                return !value || (!isNaN(value) && parseFloat(value) == value && !isNaN(parseInt(value, 10))) || 
                    i18n.t("rules.number");
            },
            integer: value => {
                value = typeof value !== undefined && value != null ? (isNaN(value) ? value : value.toString()) : "";
                return !value || (!isNaN(value) && parseFloat(value) == value && !isNaN(parseInt(value, 10))) || 
                    i18n.t("rules.number");
            },
            email: value => {
                value = typeof value !== undefined && value != null ? (isNaN(value) ? value : value.toString()) : "";
                return !value || (/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value)) || 
                    i18n.t("rules.email");
            },
            phone: value => {
                value = typeof value !== undefined && value != null ? (isNaN(value) ? value : value.toString()) : "";
                return !value || value.length === 14 || 
                    i18n.t("rules.phone");
            },
            time: value => {
                value = typeof value !== undefined && value != null ? (isNaN(value) ? value : value.toString()) : "";
                return !value || (value.length === 5 && 
                    value.split(":").length === 2 && 
                    (parseInt(value.split(":")[0]) >= 0 && parseInt(value.split(":")[0]) <= 23) && 
                    (parseInt(value.split(":")[1]) >= 0 && parseInt(value.split(":")[1]) <= 59)) || 
                    i18n.t("rules.time");
            }
        }
    }),
    computed: {
        _env() {
            return process.env;
        }
    },
    methods: {
        _request(service, data = null) {
            return new Promise(resolve => {
                fetch(process.env.VUE_APP_API, {
                    method: "post",
                    headers: { "Content-Type": "application/json;charset=utf-8" },
                    body: JSON.stringify({
                        service: service,
                        data: data
                    }),
                    mode: process.env.VUE_APP_ENV == "DEV" ? "cors" : "same-origin",
                    credentials: process.env.VUE_APP_ENV == "DEV" ? "include" : "same-origin"
                }).then(response => {
                    if(response.ok) {
                        response.json().then(r => {
                            resolve(r);
                        }).catch(() => {
                            resolve({ error: i18n.t("error") + " (1)" });
                        });
                    } else {
                        resolve({ error: i18n.t("error") + " (2)" });
                    }
                }).catch(() => {
                    resolve({ error: i18n.t("error") + " (3)" });
                });
            });
        }
    }
});