import HomeView from "./views/Home";
import HostessesView from "./views/Hostesses";
import ScheduleView from "./views/Schedule";
import RatesView from "./views/Rates";
import PromotionsView from "./views/Promotions";
import GalleryView from "./views/Gallery";
import ContactView from "./views/Contact";
import FAQRulesView from "./views/FAQ-Rules";
import HiringView from "./views/Hiring";

export default [
    {
        path: "/home", name: "home", component: HomeView,
        meta: {
            icon: "mdi-home-outline",
            breakpoints: [ "xs", "sm", "md" ]
        }
    },
    {
        path: "/hostesses", name: "hostesses", component: HostessesView,
        meta: {
            icon: "mdi-account-group-outline",
            breakpoints: [ "xs", "sm" ]
        }
    },
    {
        path: "/schedule", name: "schedule", component: ScheduleView,
        meta: {
            icon: "mdi-clock-outline",
            breakpoints: [ "xs" ]
        }
    },
    {
        path: "/rates", name: "rates", component: RatesView,
        meta: {
            icon: "mdi-cash",
            breakpoints: [ "xs", "sm"  ]
        }
    },
    {
        path: "/promotions", name: "promotions", component: PromotionsView,
        meta: {
            icon: "mdi-ticket-percent-outline",
            breakpoints: [ "xs", "sm", "md", "lg" ]
        }
    },
    {
        path: "/gallery", name: "gallery", component: GalleryView,
        meta: {
            icon: "mdi-image-multiple",
            breakpoints: [ "xs", "sm", "md", "lg" ]
        }
    },
    {
        path: "/contact", name: "contact", component: ContactView,
        meta: {
            icon: "mdi-message-text-outline",
            breakpoints: [ "xs", "sm", "md" ]
        }
    },
    {
        path: "/faq-rules", name: "faq-rules", component: FAQRulesView,
        meta: {
            icon: "mdi-frequently-asked-questions"
        }
    },
    {
        path: "/hiring", name: "hiring", component: HiringView,
        meta: {
            icon: "mdi-clipboard-account-outline"
        }
    },
    { path: "*", component: HomeView },
];