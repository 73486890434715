<template>
    <ContentLayout>
        <v-row>
            <v-col cols="12" md="6" v-for="(item, i) in items" :key="i">
                <v-card elevation="8" :class="{ 'mx-4 my-4': $vuetify.breakpoint.mdAndUp }">
                    <v-sheet color="primary" height="180px" dark>
                        <v-row no-gutters class="fill-height" align="center" justify="center">
                            <v-col cols="auto" class="text-h2 text-center">
                                <span class="antiga-font">{{ $t(item.title) }}</span>
                            </v-col>
                        </v-row>
                    </v-sheet>
                    <div v-for="(rate, r) in item.rates" :key="r">
                        <div class="text-center text-h2 my-8">
                            <div>
                                <span class="primary--text antiga-font"><sup>$</sup>{{ rate.price }}</span>
                                <span class="text-h4"><span class="antiga-font ml-4">{{ rate.time }} minutes</span></span>
                            </div>
                            <div v-if="!!rate.description">
                                <span class="text-h4"><sup class="antiga-font">{{ rate.description }}</sup></span>
                            </div>
                        </div>
                        <v-divider class="mx-4"></v-divider>
                    </div>
                    <div class="text-center text-h3">
                        <span class="text-h5 font-weight-bold"><sup class="antiga-font">{{ $t("pages.rates.terms-1") }}</sup></span>
                    </div>
                    <v-divider class="mx-4"></v-divider>
                    <v-card-actions>
                        <v-btn color="primary" text block :to="{ name: 'contact' }">
                            {{ $t("reserve") }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </ContentLayout>
</template>

<script>
    import ContentLayout from "@/components/layouts/Content";

    export default {
        name: "rates-view",
        components: { ContentLayout },
        data: () => ({
            items: [
                {
                    title: "pages.rates.type-1",
                    rates: [
                        {
                            time: "30",
                            price: "50"
                        },
                        {
                            time: "45",
                            price: "60"
                        },
                        {
                            time: "60",
                            price: "70"
                        },
                        {
                            time: "90",
                            price: "90",
                            //description: "+ 1$ / minute"
                        }
                    ]
                },
                {
                    title: "pages.rates.type-2",
                    rates: [
                        {
                            time: "30",
                            price: "70"
                        },
                        {
                            time: "45",
                            price: "80"
                        },
                        {
                            time: "60",
                            price: "90"
                        },
                        {
                            time: "90",
                            price: "110",
                            //description: "+ 1$ / minute"
                        }
                    ]
                }
            ]
        })
    };
</script>
