<template>
    <v-snackbar
        v-if="item"
        v-model="item.model"
        :multi-line="item.multiLine"
        :color="item.color"
        :light="item.light"
        :timeout="item.timeout"
        bottom right
    >
        <v-icon left small v-if="item.icon">{{ item.icon }}</v-icon>{{ item.text }}
        <template v-slot:action="{ attrs }">
            <v-btn
                v-bind="attrs"
                icon
                @click="item.model = false"
            >
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </template>
    </v-snackbar>
</template>

<script>
    export default {
        name: "snack-element",
        data: () => ({
            defaults: {
                model: true,
                text: null,
                multiLine: false,
                icon: null,
                color: "primary",
                light: false,
                timeout: 5000
            },
            item: null
        }),
        methods: {
            show(notification) {
                let item = JSON.parse(JSON.stringify(this.defaults));
                Object.assign(item, notification);
                this.item = item;
            }
        }
    };
</script>