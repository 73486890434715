<template>
    <ContentLayout>
        <v-row>
            <v-col cols="12" md="6">
                <v-card elevation="8" :class="{ 'mx-4 my-4': $vuetify.breakpoint.mdAndUp }">
                    <v-sheet color="primary" height="180px" dark tile>
                        <v-row no-gutters class="fill-height" align="center" justify="center"> <!-- background -->
                            <v-col cols="auto" class="text-center" :class="{ 'text-h2': $vuetify.breakpoint.smAndUp, 'text-h3': $vuetify.breakpoint.xs }">
                                <span class="antiga-font">{{ $t('pages.faq-rules.faq.title') }}</span>
                            </v-col>
                        </v-row>
                    </v-sheet>
                    <v-expansion-panels accordion focusable>
                        <v-expansion-panel v-for="(_, i) in 4" :key="i">
                            <v-expansion-panel-header>{{ $t('pages.faq-rules.faq.items[' + i + '].question') }}</v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <div class="pt-4">
                                    {{ $t('pages.faq-rules.faq.items[' + i + '].answer') }}
                                </div>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </v-card>
            </v-col>
            <v-col cols="12" md="6">
                <v-card elevation="8" :class="{ 'mx-4 my-4': $vuetify.breakpoint.mdAndUp }">
                    <v-sheet color="primary" height="180px" dark tile>
                        <v-row no-gutters class="fill-height" align="center" justify="center"> <!-- background -->
                            <v-col cols="auto" class="text-center" :class="{ 'text-h2': $vuetify.breakpoint.smAndUp, 'text-h3': $vuetify.breakpoint.xs }">
                                <span class="antiga-font">{{ $t('pages.faq-rules.rules.title') }}</span>
                            </v-col>
                        </v-row>
                    </v-sheet>
                    <div v-for="(_, i) in 4" :key="i">
                        <div class="text-body-2 pa-4">
                            {{ $t('pages.faq-rules.rules.items[' + i + ']') }}
                        </div>
                        <v-divider></v-divider>
                    </div>
                </v-card>
            </v-col>
        </v-row>
    </ContentLayout>
</template>

<script>
    import ContentLayout from "@/components/layouts/Content";

    export default {
        name: "faq-rules-view",
        components: { ContentLayout },
    };
</script>
