import Vue from 'vue';

import router from './plugins/router';
import store from './plugins/store';
import vuetify from './plugins/vuetify';
import { i18n } from './plugins/i18n';
import "./plugins/mask";
import "./plugins/mixins";

import 'roboto-fontface/css/roboto/roboto-fontface.css';
import '@mdi/font/css/materialdesignicons.css';

import App from './components/App.vue';

Vue.config.productionTip = false;

new Vue({
    router,
    store,
    vuetify,
    i18n,
    render: h => h(App)
}).$mount('#app');