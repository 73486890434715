<template>
    <v-app>
        <v-navigation-drawer v-model="drawer" app right dark temporary color="primary">
            <div class="background-absolute"></div>
            <v-img src="@/assets/img/lantern.png" height="100px" contain class="ma-4"></v-img>
            <v-divider></v-divider>
            <v-list dense nav>
                <v-list-item v-for="route in drawerRoutes" :key="route.name" link :to="route.name" active-class="primary darken-1">
                    <v-list-item-icon>
                        <v-icon>{{ route.meta.icon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title class="text-uppercase">{{ $t("pages." + route.name + ".title") }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
            <!--<template v-slot:append>-->
                <div class="pa-2">
                    <v-btn block outlined small @click="$root.dialogs.login.open()" v-if="!$store.state.user">
                        <v-icon left>mdi-login</v-icon>{{ $t("session.login.text") }}
                    </v-btn>
                    <v-btn block outlined small @click="$root.dialogs.login.logout()" v-else>
                        <v-icon left>mdi-logout</v-icon>{{ $t("session.logout.text") }}
                    </v-btn>
                </div>
            <!--</template>-->
        </v-navigation-drawer>
        <v-main v-if="visible">
            <v-app-bar app color="white" height="120px">
                <v-app-bar-title>
                    <router-link to="/">
                        <v-img src="@/assets/img/logo-website.png" height="auto" width="280px" contain></v-img>
                    </router-link>
                </v-app-bar-title>
                <v-spacer></v-spacer>
                <div v-if="appbarRoutes.length > 0">
                    <v-btn
                        v-for="route in appbarRoutes"
                        text large color="primary" rounded
                        :key="route.name" :to="route.name"
                        active-class="primary accent--text"
                        class="mr-4 antiga-font"
                    >
                        <v-icon v-if="!!route.meta && !!route.meta.icon" left size="22">{{ route.meta.icon }}</v-icon>
                        <h2>{{ $t("pages." + route.name + ".title") }}</h2>
                    </v-btn>
                </div>
                <LanguageElement v-if="$vuetify.breakpoint.smAndUp"></LanguageElement>
                <v-app-bar-nav-icon @click="drawer = !drawer" class="primary--text"></v-app-bar-nav-icon>
            </v-app-bar>
            <keep-alive>
                <router-view></router-view>
            </keep-alive>
        </v-main>
        <v-footer v-if="visible" dark padless inset>
            <v-card class="flex" flat tile>
                <v-card-title class="primary">
                    <v-row no-gutters class="text-h5 font-weight-bold">
                        <!--<v-col cols="12" md="8" :class="{ 'text-center': $vuetify.breakpoint.smAndDown }" v-if="$vuetify.breakpoint.smAndUp">
                            <h3 class="subheading antiga-font">{{ $t("social-network") }}</h3>
                        </v-col>-->
                        <v-col cols="12" md="3" :class="{ 'text-center': $vuetify.breakpoint.smAndDown }">
                            <v-img src="@/assets/img/payments.png" class="payments" contain></v-img>
                            <span class="subheading antiga-font ml-4">{{ $t("payments") }}</span>
                        </v-col>
                        <v-col cols="12" md="3" class="text-center" :class="{ 'my-4': $vuetify.breakpoint.smAndDown }">
                            <a :href="_env.VUE_APP_ADDRESS_LINK" class="white--text text-decoration-none" target="_blank">
                                <span class="antiga-font">
                                    <v-icon left>mdi-map-marker</v-icon> {{ _env.VUE_APP_ADDRESS }}
                                </span>
                            </a>
                        </v-col>
                        <v-col cols="12" md="3" class="text-center" :class="{ 'mb-4': $vuetify.breakpoint.smAndDown }">
                            <a :href="'tel:' + _env.VUE_APP_PHONE" class="white--text text-decoration-none" target="_blank">
                                <span class="antiga-font">
                                    <v-icon left>mdi-phone</v-icon> {{ _env.VUE_APP_PHONE }}
                                </span>
                            </a>
                        </v-col>
                        <v-col cols="12" md="3" :class="{ 'text-center': $vuetify.breakpoint.smAndDown, 'text-right': $vuetify.breakpoint.mdAndUp }">
                            <LanguageElement footer v-if="$vuetify.breakpoint.xs"></LanguageElement>
                            <v-tooltip top v-for="link in links" :key="link.value">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        v-on="on" v-bind="attrs"
                                        class="mx-4" dark icon
                                        :href="link.value"
                                        target="_blank"
                                    >
                                        <v-img v-if="!!link.tiktok" src="@/assets/img/tiktok.svg" class="tiktok" contain></v-img>
                                        <v-icon size="26px" v-else>{{ link.icon }}</v-icon>
                                    </v-btn>
                                </template>
                                {{ link.text }}
                            </v-tooltip>
                        </v-col>
                    </v-row>
                </v-card-title>
                <v-card-text class="py-2 primary darken-2 white--text text-center text-caption">
                    <div>&copy; {{ new Date().getFullYear() }} {{ _env.VUE_APP_TITLE }} — {{ $t("copyright") }}</div>
                    <div>{{ $t("developed_by") }} <a class="text-decoration-none white--text" :href="_env.VUE_APP_AUTHOR_WEBSITE" target="_blank">{{ _env.VUE_APP_AUTHOR_NAME }}</a></div>
                </v-card-text>
            </v-card>
        </v-footer>
        <SplashDialog @enter="visible = true"></SplashDialog>
        <LoginDialog ref="loginDialog"></LoginDialog>
        <SnackElement ref="snackElement"></SnackElement>
    </v-app>
</template>

<script>
    import SplashDialog from "./dialogs/Splash";
    import LoginDialog from "./dialogs/Login";
    import SnackElement from "./elements/Snack";
    import LanguageElement from "./elements/Language";

    export default {
        name: "app",
        components: { SplashDialog, LoginDialog, SnackElement, LanguageElement },
        data: () => ({
            visible: false,
            drawer: false,
            login: false,

            links: [
                { icon: "mdi-facebook", text: "Facebook", value: "https://facebook.com/lanternesrougescom" },
                { icon: "mdi-instagram", text: "Instagram", value: "https://instagram.com/lanternesrouges" },
                { tiktok: true, text: "TikTok", value: "https://www.tiktok.com/@lanternes_rouges" }
            ],
        }),
        computed: {
            appbarRoutes() {
                return this.$router.options.routes.filter(route => !!route.meta && !!route.meta.breakpoints && route.meta.breakpoints.findIndex(b => b === this.$vuetify.breakpoint.name) === -1);
            },
            drawerRoutes() {
                return this.$router.options.routes.filter(route => !!route.meta && (!route.meta.breakpoints || (!!route.meta.breakpoints && route.meta.breakpoints.findIndex(b => b === this.$vuetify.breakpoint.name) !== -1)));
            }
        },
        created() {
            // check session
            this._request("users/session").then(response => {
                if(!response.user) {
                    return;
                }
                this.$store.commit({ type: "setUser", user: response.user });
            });
        },
        mounted() {
            this.$root.dialogs = {
                login: this.$refs.loginDialog
            };
            this.$root.snack = this.$refs.snackElement;
        }
    };
</script>

<style>
    @font-face {
        font-family: 'antiga';
        src: 
        url('@/assets/fonts/antiga-regular.ttf') format('truetype'), 
        url('@/assets/fonts/antiga-regular.woff2') format('woff2');
        font-weight: normal;
        font-style: normal;
    }
    .antiga-font {
        font-family: 'antiga';
    }
    @font-face {
        font-family: 'respective';
        src: 
        url('@/assets/fonts/respective-regular.ttf') format('truetype'), 
        url('@/assets/fonts/respective-regular.woff2') format('woff2');
        font-weight: normal;
        font-style: normal;
    }
    .respective-font {
        font-family: 'respective';
    }

    .background-absolute {
        z-index: 0;
        position: absolute;
        top: 0px; bottom: 0px; left: 0px; right: 0px;
    }
    .background {
        position: relative;
    }
    .background::after, .background-absolute::after {
        z-index: 1;

        content: "";
        position: absolute;
        top: 0px; bottom: 0px; left: 0px; right: 0px;
        opacity: 0.5;

        background:
        radial-gradient(hsl(0, 100%, 27%) 4%, hsl(0, 100%, 18%) 9%, hsla(0, 100%, 20%, 0) 9%) 0 0,
        radial-gradient(hsl(0, 100%, 27%) 4%, hsl(0, 100%, 18%) 8%, hsla(0, 100%, 20%, 0) 10%) 50px 50px,
        radial-gradient(hsla(0, 100%, 30%, 0.8) 20%, hsla(0, 100%, 20%, 0)) 50px 0,
        radial-gradient(hsla(0, 100%, 30%, 0.8) 20%, hsla(0, 100%, 20%, 0)) 0 50px,
        radial-gradient(hsla(0, 100%, 20%, 1) 35%, hsla(0, 100%, 20%, 0) 60%) 50px 0,
        radial-gradient(hsla(0, 100%, 20%, 1) 35%, hsla(0, 100%, 20%, 0) 60%) 100px 50px,
        radial-gradient(hsla(0, 100%, 15%, 0.7), hsla(0, 100%, 20%, 0)) 0 0,
        radial-gradient(hsla(0, 100%, 15%, 0.7), hsla(0, 100%, 20%, 0)) 50px 50px,
        linear-gradient(45deg, hsla(0, 100%, 20%, 0) 49%, hsla(0, 100%, 0%, 1) 50%, hsla(0, 100%, 20%, 0) 70%) 0 0,
        linear-gradient(-45deg, hsla(0, 100%, 20%, 0) 49%, hsla(0, 100%, 0%, 1) 50%, hsla(0, 100%, 20%, 0) 70%) 0 0;
        background-color: #300;
        background-size: 100px 100px;
    }
    .background::before, .background-absolute::before {
        z-index: 2;

        content: "";
        position: absolute;
        top: 0px; bottom: 0px; left: 0px; right: 0px;
        opacity: 0.8;

        background: linear-gradient(180deg, rgba(0,0,0,0) 60%, #000 100%);
    }
    .background > *, .background-absolute > * {
        z-index: 3;
    }

    .tiktok {
        height: 20px;
        width: 14px;
    }
    .payments {
        display: inline-block;
        height: auto;
        width: 120px;
    }
</style>